var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { computed, defineComponent, inject, onBeforeMount, ref, } from 'vue';
import { useField } from 'vee-validate';
import * as yup from 'yup';
import i18n from '@/i18n';
import Swal from 'sweetalert2';
export default defineComponent({
    setup: function () {
        var _this = this;
        var authStore = inject('authState');
        var configurationStore = inject('configurationStore');
        var validatePhoneTooltipMessage = ref('');
        var setValidatePhoneTooltipMessage = function () {
            if (authStore.state.userData.mobileNum === null) {
                validatePhoneTooltipMessage.value =
                    i18n.global.t('ADD_PHONE_TO_VALIDATE');
                return;
            }
            if (authStore.state.userData.mobileNum !== null &&
                authStore.state.userData.mobileValidated) {
                validatePhoneTooltipMessage.value = i18n.global.t('PHONE_ALREADY_VALIDATED');
                return;
            }
        };
        var showValidatePhoneToolTipMessage = ref(false);
        var setShowValidatePhoneToolTipMessage = function (value) {
            showValidatePhoneToolTipMessage.value = value;
        };
        var _a = useField('phone', yup
            .string()
            .required(i18n.global.t('FIELD_REQUIRED'))
            .matches(/^[0-9]+$/, i18n.global.t('WRONG_PHONE_FROMAT'))
            .min(9, i18n.global.t('WRONG_PHONE_FROMAT'))
            .max(9, i18n.global.t('WRONG_PHONE_FROMAT'))), errorPhone = _a.errorMessage, phone = _a.value;
        var _b = useField('phoneValidationCode', yup
            .string()
            .required(i18n.global.t('FIELD_REQUIRED'))
            .matches(/^[0-9]+$/, i18n.global.t('WRONG_VALIDATIONCODE_FORMAT'))), errorPhoneValidationCode = _b.errorMessage, phoneValidationCode = _b.value;
        var phoneFormValid = computed(function () { return !!phone.value && !errorPhone.value; });
        var samePhone = computed(function () { return authStore.state.userData.mobileNum == phone.value; });
        var validationCodeFormValid = computed(function () {
            return !!phoneValidationCode.value && !errorPhoneValidationCode.value;
        });
        var updatePhoneNumber = function () { return __awaiter(_this, void 0, void 0, function () {
            var success;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!phoneFormValid) {
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, configurationStore.newMobile({
                                mobile: phone.value
                            })];
                    case 1:
                        success = _a.sent();
                        if (success === true) {
                            authStore.getUserDetails();
                            Swal.fire({
                                icon: 'success',
                                title: i18n.global.t('tester_mobile_validated'),
                                text: i18n.global.t('tester_email_validate_mobile'),
                            });
                        }
                        else if (success === 'registrationMobileFraud') {
                            Swal.fire({
                                icon: 'error',
                                title: i18n.global.t('tester_validate_mobile_error')
                            });
                        }
                        else {
                            Swal.fire({
                                icon: 'error',
                                title: i18n.global.t('Error_Try_Again'),
                                text: i18n.global.t('tester_mobile_validation_check'),
                            });
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        var showValidatePhoneNumberForm = function () { return __awaiter(_this, void 0, void 0, function () {
            var resendMobileConfirmation, container, br, resendPhoneValidationCode, code;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setValidatePhoneTooltipMessage();
                        if (authStore.state.userData.mobileNum == null ||
                            authStore.state.userData.mobileValidated) {
                            setShowValidatePhoneToolTipMessage(true);
                            setTimeout(function () {
                                setShowValidatePhoneToolTipMessage(false);
                            }, 2000);
                            return [2 /*return*/];
                        }
                        resendMobileConfirmation = function () { return __awaiter(_this, void 0, void 0, function () {
                            var success;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, configurationStore.resendMobileConfirmation()];
                                    case 1:
                                        success = _a.sent();
                                        if (success) {
                                            Swal.fire({
                                                icon: 'success',
                                                title: i18n.global.t('tester_mobile_validation_resend'),
                                                text: i18n.global.t('tester_email_validate_mobile'),
                                            });
                                        }
                                        else {
                                            Swal.fire({
                                                icon: 'error',
                                                title: i18n.global.t('Error_Try_Again'),
                                            });
                                        }
                                        return [2 /*return*/];
                                }
                            });
                        }); };
                        container = document.createElement('div');
                        container.innerText = i18n.global.t('tester_mobile_validation_resend2');
                        br = document.createElement('span');
                        br.innerHTML = '<br/>';
                        container.appendChild(br);
                        resendPhoneValidationCode = document.createElement('a');
                        resendPhoneValidationCode.addEventListener('click', resendMobileConfirmation);
                        resendPhoneValidationCode.innerText = i18n.global.t('tester_mobile_validation_resend_action');
                        resendPhoneValidationCode.style.cursor = 'pointer';
                        container.appendChild(resendPhoneValidationCode);
                        return [4 /*yield*/, Swal.fire({
                                title: i18n.global.t('tester_inser_validation_code'),
                                html: container,
                                input: 'text',
                                inputLabel: i18n.global.t('tester_validation_code'),
                                showCancelButton: true,
                                backdrop: 'transparent',
                                showClass: {
                                    popup: 'animate__animated animate__bounceIn animate__fast',
                                },
                                hideClass: {
                                    popup: 'animate__animated animate__bounceOut animate__fast',
                                },
                                inputValidator: function (value) {
                                    if (value === '') {
                                        return i18n.global.t('tester_required_input');
                                    }
                                    if (isNaN(Number(value))) {
                                        return i18n.global.t('tester_required_number');
                                    }
                                    return null;
                                },
                            })];
                    case 1:
                        code = (_a.sent()).value;
                        if (code) {
                            validatePhoneNumber();
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        var validatePhoneNumber = function () { return __awaiter(_this, void 0, void 0, function () {
            var success;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, configurationStore.validateMobile(authStore.state.userData.identifier, { validationCode: phoneValidationCode.value })];
                    case 1:
                        success = _a.sent();
                        if (success) {
                            Swal.fire({
                                icon: 'success',
                                title: i18n.global.t('tester_mobile_validated_msg'),
                                text: i18n.global.t('tester_mobile_validated_msg2'),
                            });
                            setValidatePhoneTooltipMessage();
                            return [2 /*return*/];
                        }
                        Swal.fire({
                            icon: 'error',
                            title: i18n.global.t('tester_mobile_incorrect'),
                            text: i18n.global.t('tester_mobile_not_valid'),
                        });
                        return [2 /*return*/];
                }
            });
        }); };
        onBeforeMount(function () { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!authStore.state.userData.mobileNum) return [3 /*break*/, 2];
                        _a = phone;
                        return [4 /*yield*/, authStore.state.userData.mobileNum];
                    case 1:
                        _a.value = _b.sent();
                        _b.label = 2;
                    case 2:
                        setValidatePhoneTooltipMessage();
                        return [2 /*return*/];
                }
            });
        }); });
        return {
            authStore: authStore,
            phone: phone,
            errorPhone: errorPhone,
            phoneValidationCode: phoneValidationCode,
            errorPhoneValidationCode: errorPhoneValidationCode,
            phoneFormValid: phoneFormValid,
            updatePhoneNumber: updatePhoneNumber,
            validatePhoneTooltipMessage: validatePhoneTooltipMessage,
            setValidatePhoneTooltipMessage: setValidatePhoneTooltipMessage,
            showValidatePhoneToolTipMessage: showValidatePhoneToolTipMessage,
            setShowValidatePhoneToolTipMessage: setShowValidatePhoneToolTipMessage,
            showValidatePhoneNumberForm: showValidatePhoneNumberForm,
            samePhone: samePhone
        };
    },
});
