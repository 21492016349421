import Swal from 'sweetalert2';
import SwitchInput from "../../../commonvue/src/components/SwitchInput/SwitchInput.vue";
import i18n from '@/i18n';
import { defineComponent, inject, ref } from 'vue';
export default defineComponent({
    components: {
        SwitchInput: SwitchInput
    },
    data: function () {
        return {
            notifTypes: [
                { value: 'push', label: i18n.global.t('NOTIFICATION_TYPE_MOBILE') },
                { value: 'email', label: i18n.global.t('NOTIFICATION_TYPE_EMAIL') }
            ]
        };
    },
    setup: function () {
        var configurationStore = inject('configurationStore');
        var authStore = inject('authState');
        var notifNewProject = ref(authStore.state.userData.notifNewProject);
        var notifPush = ref(authStore.state.userData.notifPush);
        var notificationsEnabled = ref(false);
        var selectedNotifType = ref('push');
        if (notifNewProject.value || notifPush.value) {
            notificationsEnabled.value = true;
        }
        else {
            notificationsEnabled.value = false;
        }
        if (notifNewProject.value) {
            selectedNotifType.value = 'email';
        }
        else if (notifPush.value) {
            selectedNotifType.value = 'push';
        }
        var updateNotifications = function () {
            var data = {
                notifByEmail: notifNewProject.value,
                notifByPush: notifPush.value
            };
            if (notificationsEnabled.value) {
                if (selectedNotifType.value == 'email') {
                    data.notifByEmail = true;
                    data.notifByPush = false;
                }
                else if (selectedNotifType.value == 'push') {
                    data.notifByEmail = false;
                    data.notifByPush = true;
                }
            }
            else {
                data.notifByEmail = false;
                data.notifByPush = false;
            }
            var success = configurationStore.UpdateNotifications(data);
            if (!success) {
                Swal.fire({
                    icon: 'error',
                    title: i18n.global.t('Error_Try_Again'),
                    text: i18n.global.t('tester_check_email'),
                });
                return;
            }
            else {
                Swal.fire({
                    icon: 'success',
                    title: i18n.global.t('notification_new_value_save'),
                    text: i18n.global.t('notification_new_value_text'),
                });
            }
        };
        return {
            notificationsEnabled: notificationsEnabled,
            selectedNotifType: selectedNotifType,
            updateNotifications: updateNotifications,
            authStore: authStore
        };
    }
});
